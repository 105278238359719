import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../interfaces/constants';
import { AuthToken } from '../interfaces/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  AuthorizeUser(_userId: string): Observable<AuthToken>{

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('client_id', _userId);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<AuthToken>(`token`, body.toString(),{ headers: headers});
  }
}
