import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {StorageService} from "../services/storage.service";
import {formatDate} from "@angular/common";
import {AuthToken} from "../interfaces/token";
import {StorageVariables} from "../interfaces/constants";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let dateTime = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss SSS a", "en");

    req = req.clone({
      headers: req.headers.set('UIRequestTimestamp', dateTime)
    });

    if (req.url.includes(`token`)) return next.handle(req);

    const TOKEN = this.storageService.GetStorageVariable<AuthToken>(StorageVariables.SESSION)?.access_token ?? "";

    if(!TOKEN) throw "No cuentas con un token de autorizacion para realizar esta petición";

    let headers: HttpHeaders = req.headers.set(`Authorization`, `Bearer ${TOKEN}`);

    const clonedRequest = req.clone({headers});

    return next.handle(clonedRequest);
  }
}
