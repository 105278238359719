import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageVariables } from '../interfaces/constants';
import { AuthToken } from '../interfaces/token';
import { AlertService } from '../services/alert.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  constructor(private router: Router,
              private localStorageService: StorageService,
              private alertService: AlertService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const TOKEN = this.localStorageService.GetStorageVariable<AuthToken>(StorageVariables.SESSION);

    if(TOKEN && TOKEN.access_token)
    {
      return true;
    }

    this.alertService.Alert("error", "No estas autenticado o autorizado para ingresar a esta página");
    
    
    return false;
  }
  
}
