import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AccessGuard } from './guards/access.guard';
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AccessGuard]
  },
  {
    path: "home/:id",
    component: HomeComponent
  },
  {
    path: 'upload-template',
    loadChildren: () => import('./pages/upload-template/upload-template.module').then(m => m.UploadTemplateModule),
    canActivate: [AccessGuard]
  },
  {
    path: 'uploaded-templates',
    loadChildren: () => import('./pages/uploaded-template/uploaded-template.module').then(m => m.UploadedTemplateModule),
    canActivate: [AccessGuard]
  },
  {
    path: 'societies',
    loadChildren: () => import('./pages/societies/societies.module').then(m => m.SocietiesModule),
    canActivate: [AccessGuard]
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AccessGuard]
  },
  {
    path: '**',
    pathMatch: "full",
    redirectTo: "home"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
