<div class="settings-container">
    <div class="setting-sidemenu">
        <mat-selection-list #options [multiple]="false">
            <div mat-subheader>Opciones</div>
            <mat-list-option (click)="file.click()">
                <mat-icon mat-list-icon>attach_file</mat-icon>
                <span mat-line>Adjuntar plantilla Excel</span>
                <input #file type="file" class="hidden" (change)="OnFileDropped($event, file)">
            </mat-list-option>
            <mat-list-option  (click)="UploadInvoiceTemplate()" *ngIf="showUploadOption">
                <mat-icon mat-list-icon>file_upload</mat-icon>
                <span mat-line>Subir <span class="fw-3">{{ templateName }}</span></span>
            </mat-list-option>
          </mat-selection-list>
    </div>
</div>
