import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { CommonService } from 'src/app/services/common.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  file!: File;
  templateName!:  string;
  showUploadOption!: boolean;

  constructor(private uploadFileService: TemplateService,
              private spinner: NgxSpinnerService,
              private commonService: CommonService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.file = {} as File;

    this.templateName = "";

    this.showUploadOption = false;
  }

  UploadInvoiceTemplate(): void {
    this.spinner.show("spinner");

    let frmdata = new FormData();

    frmdata.append('file', this.file);

    this.uploadFileService.UploadInvoiceTemplate(frmdata).pipe(finalize(()=>this.spinner.hide("spinner"))).subscribe({
      next: (next) => {
        if(next.Result)
        {
          this.showUploadOption = false;

          this.alertService.Alert("success", "Archivo guardado correctamente");
        }
        else
        {
          console.error(`${next.ErrorInfo.Code} - ${next.ErrorInfo.Message}`);

          this.alertService.Alert("error", `${next.ErrorInfo.Code} - ${next.ErrorInfo.Message}`);
        }
      },
      error: (error) => {
        console.error(error);

        this.alertService.Alert("error", error);
      }
    })
  }

  OnFileDropped(_event: any, _input: HTMLInputElement): void{
    try
    {
      this.PrepareFilesList(_event.target.files[0],_input);
    }
    catch(exception)
    {
      console.log(exception);
    }
  }

  PrepareFilesList(_file: any, _input: HTMLInputElement): void {
    try
    {
      if(['XLS', 'XLSX'].includes(_file.name.split('.').pop().toUpperCase()))
      {
        this.file = {} as File;
        this.file = _file;
        this.templateName = this.file.name;
        this.showUploadOption = true;
      }
      else
      {
        this.alertService.Alert('error','Solo se permiten archivos .xls, .xlsx');
      }

      _input.value = '';
    }
    catch(exception)
    {
      console.error(exception);
    }
  }

}
