import { Injectable } from '@angular/core';
import { StorageVariables } from '../interfaces/constants';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  GetStorageVariable<T>(_key: StorageVariables, _defaultReturnValue?: any): T{
    const VALUE = localStorage.getItem(_key);

    return VALUE ? JSON.parse(VALUE) : _defaultReturnValue;
  }

  SetStorageVariable<T>(_key: StorageVariables, _value: T): void{
    localStorage.setItem(_key, JSON.stringify(_value));
  }

  RemoveStorageVariable(_key: StorageVariables):void{
    localStorage.removeItem(_key);
  }
}
