import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { StorageVariables } from 'src/app/interfaces/constants';
import { AuthToken } from 'src/app/interfaces/token';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userId!: string;
  username!: string;

  constructor(private activateRoute: ActivatedRoute,
              private authService: AuthService,
              private storageService: StorageService,
              private router: Router,
              private commonService: CommonService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.AuthorizeUser();

    this.username  = this.storageService.GetStorageVariable<AuthToken>(StorageVariables.SESSION)?.UserName ?? "";
    
    setTimeout(() => {this.commonService.SetPageTitle("Inicio");}, 0);

    this.commonService.GetUserName().subscribe({
      next: (next: string) => { this.username = next; },
      error: (error) => console.error(error)
    }); 
  }

  AuthorizeUser(): void{
    
    if(this.router.url === "/home") return;

    this.spinner.show("spinner");

    this.userId = this.activateRoute.snapshot.paramMap.get("id") ?? '';
    
    this.authService.AuthorizeUser(this.userId).pipe(finalize(() => this.spinner.hide("spinner"))).subscribe({
      next: (next: AuthToken) => {
        this.storageService.SetStorageVariable(StorageVariables.SESSION, next);

        this.commonService.SetUserName(next.UserName);

        this.username = next.UserName;

        //Lo redirecciono a home para que no salga el id en la URL
        this.router.navigateByUrl("/home");
      },
      error: (error) => {
        console.error(error);
        this.storageService.RemoveStorageVariable(StorageVariables.SESSION);
        this.username = "";
        this.commonService.SetUserName("");
        this.alertService.Alert("error", error);
      }
    });
  }
}
