<mat-drawer-container class="sidemenu-container" autosize>
  <mat-drawer #drawer class="side-menu" mode="side">
    <button mat-button class="menu-button" routerLink="/home" (click)="drawer.toggle()">
      {{ title }}
    </button>
    <mat-tree [dataSource]="menuDataSource" [treeControl]="menuTreeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-button class="menu-button" (click)="OnClickMenuOption(node, drawer)">
          <mat-icon class="menu-icons-color">{{node.Icon}}</mat-icon>
          {{node.Name}}
        </button>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node;when: menuOptionHasChilds" matTreeNodePadding>
        <button mat-button matTreeNodeToggle class="menu-button" (click)="OnClickMenuOption(node)">
          <mat-icon class="menu-icons-color">{{node.Icon}}</mat-icon>
          {{node.Name}}
        </button>
      </mat-tree-node>
    </mat-tree>
  </mat-drawer>
  <div class="main-container">
    <div class="header">
      <button mat-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
        {{ pageTitle }}
      </button>

      <div class="username">
        <span>{{ username }}</span>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
<ngx-spinner name="spinner" bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="line-scale"
  [fullScreen]="true">
  <p style="color: white"> Cargando... </p>
</ngx-spinner>