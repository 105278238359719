import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageVariables } from './interfaces/constants';
import { Menu, MenuLevels } from './interfaces/menu';
import { AuthToken } from './interfaces/token';
import { CommonService } from './services/common.service';
import { StorageService } from './services/storage.service';
import MenuOptions from "../assets/menu.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title: string = 'Facturación Montecristo';
  username!: string;
  pageTitle!: string;
  menuTreeControl!: FlatTreeControl<MenuLevels>;
  menuTreeFlattener!: MatTreeFlattener<Menu, MenuLevels>;
  menuDataSource!: MatTreeFlatDataSource<Menu, MenuLevels, MenuLevels>;
  userId!: string;

  constructor(private router: Router,
              private commonService: CommonService,
              private storageService: StorageService,
              private spinner: NgxSpinnerService){}

  ngOnInit(): void {
    this.pageTitle = "";
    this.username = "";
    this.menuTreeControl = new FlatTreeControl<MenuLevels>(node => node.level, node => node.expandable);
    this.menuTreeFlattener = new MatTreeFlattener<Menu, MenuLevels>(
      this._transformer,
      node => node.level,
      node => node.expandable,
      node => node.Children,
    );
    this.menuDataSource = new MatTreeFlatDataSource(this.menuTreeControl, this.menuTreeFlattener);

    this.menuDataSource.data = this.GetMenuOptions();

    this.username  = this.storageService.GetStorageVariable<AuthToken>(StorageVariables.SESSION)?.UserName ?? "";

    this.InitSubscriptions();
    this.GetMenuOptions();
  }

  InitSubscriptions(): void{
    this.commonService.GetUserName().subscribe({
      next: (next) => { this.username = next }
    });
    this.commonService.GetPageTitle().subscribe({
      next: (next) => { this.pageTitle = next }
    });
  }

  menuOptionHasChilds = (_: number, node: MenuLevels) => node.expandable;

  OnClickMenuOption(option: MenuLevels, drawer?: MatDrawer): void{
    drawer?.toggle();
    this.router.navigateByUrl(option.Route);
  }

  private _transformer = (node: Menu, level: number) => {
    return {
      expandable: node.Children && node.Children.length > 0,
      level: level,
      Name: node.Name,
      Route: node.Route,
      Icon: node.Icon
    } as MenuLevels;
  }

  private GetMenuOptions(): Menu[] {
    return MenuOptions;
  }


}
