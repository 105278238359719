import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private username$ = new Subject<string>();
  private pageTitle$ = new Subject<string>();

  constructor() { }

  SetUserName(_value: string): void{
    this.username$.next(_value);
  }

  GetUserName(): Observable<string>{
    return this.username$.asObservable();
  }

  SetPageTitle(_value: string): void{
    this.pageTitle$.next(_value);
  }

  GetPageTitle(): Observable<string>{
    return this.pageTitle$.asObservable();
  }

  DownloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ) {
    let report = new Blob([this.StringToArrayBuffer(atob(base64File))], {
      type: blobType,
    });

    let link = document.createElement("a");

    link.href = window.URL.createObjectURL(report);

    link.download = `${fileName}.${fileExtension}`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  private StringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);

    let view = new Uint8Array(buf);

    for (let i = 0; i != toConvert.length; ++i)
    {
      view[i] = toConvert.charCodeAt(i) & 0xff;
    }

    return buf;
  }
}
