import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../interfaces/constants';
import { IProcessedDocumentResponse } from '../interfaces/document';
import { BaseResponse, Response } from '../interfaces/responses';
import { IDownloadFile } from '../interfaces/upload-file';
import { UserSAPCredentials } from '../interfaces/user';
import {ITemplateFilter} from "../interfaces/i-filter";
import {ITemplate, ITemplateLineInfo, ITemplateWithStatistics} from "../interfaces/i-template";
import {D} from "@angular/cdk/keycodes";
import {KeyValuePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private URL: string = `api/Template`;

  constructor(private http: HttpClient) { }

  UploadTemplate(_file: FormData, _credential: UserSAPCredentials): Observable<Response<number>>{

    _file.append("Credentials", JSON.stringify(_credential));

    return this.http.post<Response<number>>(`${this.URL}/UploadTemplate`, _file);
  }

  DownloadInvoiceTemplate(): Observable<Response<IDownloadFile>>{
    return this.http.get<Response<IDownloadFile>>(`${this.URL}/DownloadInvoicesTemplate`);
  }

  UploadInvoiceTemplate(_file: FormData): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.URL}/UploadInvoiceTemplate`, _file);
  }

  GetTemplates(_filters: ITemplateFilter): Observable<Response<ITemplateWithStatistics[]>>
  {
    let newFilter = {
      From: (_filters.From || new Date()).toISOString(),
      Skip: _filters.Skip || 0,
      Status: _filters.Status || 'ALL',
      Take: _filters.Take || 0,
      To: (_filters.To || new Date()).toISOString()
    };

    let params = new HttpParams({fromObject: newFilter});

    return this.http.get<Response<ITemplateWithStatistics[]>>(`${this.URL}/GetTemplates`, {params});
  }

  GetTemplatesCount(_filters: ITemplateFilter): Observable<Response<number>>
  {
    let newFilter = {
      From: (_filters.From || new Date()).toISOString(),
      Status: _filters.Status || 'ALL',
      To: (_filters.To || new Date()).toISOString()
    };

    let params = new HttpParams({fromObject: newFilter});

    return this.http.get<Response<number>>(`${this.URL}/GetTemplatesCount`, {params});
  }

  GetTemplateLines(_filters: ITemplateFilter): Observable<Response<ITemplateLineInfo[]>>
  {
    let newFilter = {
      From: (_filters.From || new Date()).toISOString(),
      Skip: _filters.Skip || 0,
      Status: _filters.Status || 'ALL',
      TemplateId: _filters.TemplateId || 0,
      Take: _filters.Take || 0,
      To: (_filters.To || new Date()).toISOString()
    };

    let params = new HttpParams({fromObject: newFilter});

    return this.http.get<Response<ITemplateLineInfo[]>>(`${this.URL}/GetTemplateLines`, {params});
  }


  ProcessTemp(): Observable<string>
  {
    return this.http.get<string>(`${this.URL}/ProcessTemp`);
  }

  ReadTempLines(): Observable<string>
  {
    return this.http.get<string>(`${this.URL}/ReadTempLines`);
  }
}
