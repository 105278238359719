import { formatDate } from "@angular/common";
import { Menu } from "./menu";

export class AppConstants{
    static GetTime(): string {
        let dateTime = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss a", "en");

        return dateTime;
    }

    public static GetError(_error: any): string {
        let joined_error;

        if(_error.error && _error.error.errorInfo){
          joined_error = _error.error.errorInfo.Message;

        } else if(_error.error && _error.error.error_description){
          joined_error = _error.error.error_description;

        } else if(_error.error && _error.error.Message){
          joined_error = _error.error.Message;

        } else if (_error.message) {
          joined_error = _error.message;

        } else if (_error.errorInfo && _error.errorInfo.Message) {
          joined_error = _error.errorInfo.Message;

        } else if(_error.error){
          joined_error = _error.error;

        } else{
          joined_error = _error;
        }

        if (typeof joined_error === 'string') return joined_error;
        return JSON.stringify(_error);
    }
}

export enum BoYesNoEnum {
    tNO,
    tYES
}


export enum BoStatus
{
    bost_Open = 0,
    bost_Close = 1,
    bost_Paid = 2,
    bost_Delivered = 3
}

export enum StorageVariables{
    SESSION = "Session",
}
