import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult, SweetAlertOptions } from 'sweetalert2';
import { AppConstants } from '../interfaces/constants';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() {
  }

  Alert(_icon: SweetAlertIcon = 'success', _message: string = ``,) {
    let html: string = ``;
    
    let settings: SweetAlertOptions = {
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      icon: _icon,
      customClass: {
        confirmButton: "alert-button-color"
      }
    };
    
    if (_message) {
      html += `<h5 style="text-align: center;">${_message}</h5>`;
    }   
    
    html += ` <p style="text-align: center;"><span style="font-size: 13px;">${AppConstants.GetTime()}</span></p>`
    
    settings.html = html;

    return Swal.fire(settings).then((result) => {
      return result.value;
    });
  }

  ConfirmAlert(_icon: SweetAlertIcon,_text: string): Promise<SweetAlertResult<any>>{
    return Swal.fire({
      text: _text,
      icon: _icon,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Continuar",
      customClass: {
        cancelButton: 'alert-button-color',
        confirmButton: 'alert-button-color'
      }
    });
  }
}
